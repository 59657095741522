import { FC, VFC, useState, useCallback, useMemo, useEffect } from "react";

import { isEmpty } from "lodash";
import pluralize from "pluralize";
import { useToasts } from "react-toast-notifications";
import { Text, Flex, Box, Image } from "theme-ui";

import { Filters, getHasuaExpFromFilters, syncFilterDefinitions } from "src/components/filter";
import { CreateViewModal } from "src/components/filter/create-view";
import { Views } from "src/components/filter/views";
import { Labels } from "src/components/labels/labels";
import { Page } from "src/components/layout";
import { Permission } from "src/components/permission";
import placeholderDestination from "src/components/permission/destination.svg";
import { PermissionProvider, usePermission } from "src/contexts/permission-context";
import {
  ResourcePermissionGrant,
  SyncsBoolExp,
  SyncsOrderBy,
  useDeleteSyncsMutation,
  useSyncsQuery,
  useUpdateSyncsMutation,
} from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { Avatar } from "src/ui/avatar";
import { ObjectBadge } from "src/ui/badge";
import { Row, Column } from "src/ui/box";
import { Button, DropdownButton } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { ChevronDownIcon, ExternalLinkIcon, InfoIcon, LabelIcon } from "src/ui/icons";
import { SearchInput } from "src/ui/input";
import { Link } from "src/ui/link";
import { Menu } from "src/ui/menu";
import { Modal } from "src/ui/modal";
import { Popout } from "src/ui/popout";
import { Table, Pagination, useTableConfig, TableColumn } from "src/ui/table";
import { useFiltering } from "src/ui/table/use-filtering";
import { useRowSelect } from "src/ui/table/use-row-select";
import { Tooltip } from "src/ui/tooltip";
import { useDestinations } from "src/utils/destinations";
import { useNavigate } from "src/utils/navigate";
import { SyncStatusBadge, getObjectName } from "src/utils/syncs";
import { formatDate, formatDatetime } from "src/utils/time";
import { openUrl } from "src/utils/urls";

enum SortKeys {
  Status = "status",
  SegmentName = "segment.name",
  DestinationName = "destination.name",
  LastRun = "sync_requests_aggregate.max.created_at",
  CreatedAt = "created_at",
}

const SyncsContent: VFC = () => {
  const { addToast } = useToasts();
  const { unauthorized } = usePermission();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [loading, setLoading] = useState<boolean>(true);
  const [createViewModalOpen, setCreateViewModalOpen] = useState(false);
  const [processingAction, setProcessingAction] = useState(false);

  const userCanWrite = !unauthorized;

  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<SyncsOrderBy>({
    defaultSortKey: "created_at",
    sortOptions: Object.values(SortKeys),
  });

  const {
    state: { creatingView, filters, selectedView, viewNotSaved, views, updatingView },
    actions: { createView, deleteView, resetViewFilters, selectView, updateCurrentView, updateFilters },
  } = useFiltering({ viewKey: "sync" });

  const hasuraFilters = useMemo(() => {
    const hasuraFilters: SyncsBoolExp = {
      ...getHasuaExpFromFilters(syncFilterDefinitions, filters),
    };

    if (search) {
      const searchFilters: SyncsBoolExp[] = [
        { segment: { name: { _ilike: `%${search}%` } } },
        { destination: { name: { _ilike: `%${search}%` } } },
        { destination: { type: { _ilike: `%${search}%` } } },
      ];

      return { _and: [hasuraFilters, { _or: searchFilters }] };
    }

    return hasuraFilters;
  }, [filters, search]);

  // used for filters
  const { data: allSyncs } = useSyncsQuery({ offset: 0, limit: 1000 });

  const {
    data: syncsData,
    error: syncsError,
    isLoading: initialLoading,
    isRefetching,
  } = useSyncsQuery(
    {
      offset,
      limit,
      filters: hasuraFilters,
      orderBy,
    },
    {
      refetchInterval: 3000,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const { mutateAsync: bulkDeleteSyncs, isLoading: loadingBulkDelete } = useDeleteSyncsMutation();
  const { mutateAsync: updateSyncs } = useUpdateSyncsMutation();

  const bulkUpdateStatus = async (enabled: boolean) => {
    setProcessingAction(true);

    return await updateSyncs(
      {
        ids: selectedRows.map(String),
        object: {
          schedule_paused: !enabled,
        },
      },
      {
        onSuccess: () => {
          addToast(`Selected syncs were ${enabled ? "enabled" : "disabled"}`, {
            appearance: "success",
          });

          onRowSelect([]);
          setProcessingAction(false);
          setLoading(true);
        },
        onError: (error) => addToast(error.message, { appearance: "error", autoDismiss: false }),
      },
    );
  };

  const bulkDelete = async () => {
    setProcessingAction(true);

    if (userCanWrite) {
      await bulkDeleteSyncs(
        { ids: selectedRows.map(String) },
        {
          onSuccess: () => {
            addToast(`Selected syncs deleted!`, {
              appearance: "success",
            });
            onRowSelect([]);
          },
          onError: (error) => addToast(error.message, { appearance: "error", autoDismiss: false }),
        },
      );

      setConfirmingDelete(false);
    } else {
      addToast("Delete not allowed. Please check your permissions.");
    }

    setProcessingAction(false);
  };

  const {
    data: { definitions: destinationDefinitions },
    error: destinationsError,
  } = useDestinations();

  const syncs = syncsData?.syncs;
  const syncsCount = syncsData?.syncs_aggregate?.aggregate?.count ?? 0;

  const columns = useMemo(
    (): TableColumn[] =>
      [
        {
          name: "Status",
          sortDirection: orderBy?.status,
          onClick: () => onSort(SortKeys.Status),
          min: "130px",
          max: "130px",
          cell: ({ status, sync_requests }) => {
            const syncRequest = sync_requests?.[0];
            const request = syncRequest ? syncRequest : { status_computed: status };
            return <SyncStatusBadge request={request} status={status} />;
          },
        },
        {
          name: "Model",
          sortDirection: orderBy?.segment?.name,
          onClick: () => onSort(SortKeys.SegmentName),
          cell: ({ segment }) => {
            return (
              <Text sx={{ fontWeight: "semi", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                {segment?.name || "Private Model"}
              </Text>
            );
          },
        },
        {
          name: "Destination",
          sortDirection: orderBy?.destination?.name,
          onClick: () => onSort(SortKeys.DestinationName),
          cell: ({ config, destination }) => {
            const definition = destinationDefinitions?.find((d) => d.type === destination?.type);
            return (
              <Tooltip disabled={definition && destination} text="This destination is only visible to some users">
                <Row sx={{ alignItems: "center" }}>
                  <Image
                    alt={definition?.name ?? "Private Destination"}
                    src={definition?.icon ?? placeholderDestination}
                    sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", flexShrink: 0, mr: 2 }}
                  />
                  <Text sx={{ fontWeight: "semi", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                    {destination?.name || definition?.name || "Private Destination"}
                  </Text>
                  {config?.object && <ObjectBadge sx={{ ml: 2 }}>{getObjectName(config.object)}</ObjectBadge>}
                </Row>
              </Tooltip>
            );
          },
        },
        {
          name: "Last run",
          sortDirection: orderBy?.sync_requests_aggregate?.max?.created_at,
          onClick: () => onSort(SortKeys.LastRun),
          max: "200px",
          cell: ({ id, sync_requests }) => {
            const syncRequest = sync_requests?.[0];

            return (
              <Flex sx={{ alignItems: "center" }}>
                {syncRequest && (
                  <>
                    <Text sx={{ mr: 2, fontWeight: "semi" }}>{formatDatetime(syncRequest?.created_at)}</Text>
                    <Link to={`/syncs/${id}/runs/${syncRequest?.id}`}>
                      <Box sx={{ color: "base.4", ":hover": { color: "secondary" } }}>
                        <ExternalLinkIcon size={14} />
                      </Box>
                    </Link>
                  </>
                )}
              </Flex>
            );
          },
        },
        {
          name: "Created At",
          max: "max-content",
          sortDirection: orderBy?.created_at,
          onClick: () => onSort(SortKeys.CreatedAt),
          cell: ({ created_at: timestamp, created_by_user }) => {
            const name = created_by_user?.name;

            if (!name && !timestamp) {
              return <Text sx={{ fontWeight: "semi" }}>-</Text>;
            }

            if (!name) {
              return <Text sx={{ fontWeight: "semi" }}>{formatDate(timestamp)}</Text>;
            }

            return (
              <Row sx={{ alignItems: "center" }}>
                <Text sx={{ fontWeight: "semi" }}>{formatDate(timestamp)}</Text>
                <Text sx={{ color: "base.6", mr: 1 }}>&nbsp;by</Text>
                <Avatar name={name} />
              </Row>
            );
          },
        },
        {
          key: "tags",
          cell: (tags) => {
            if (isEmpty(tags)) {
              return null;
            }

            return (
              <Popout
                content={() => <Labels labels={tags} sx={{ maxWidth: "200px" }} />}
                contentSx={{ p: 3, minWidth: "90px" }}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                <LabelIcon size={16} sx={{ ":hover": { svg: { fill: "primary" } } }} />
              </Popout>
            );
          },
        },
      ].filter(Boolean),
    [destinationDefinitions, orderBy, onSort],
  );

  const onRowClick = useCallback(({ id }, event) => openUrl(`/syncs/${id}`, navigate, event), [navigate]);

  useEffect(() => {
    setPage(0);
  }, [hasuraFilters]);

  useEffect(() => {
    onRowSelect([]);
  }, [page]);

  useEffect(() => {
    setLoading(true);
  }, [limit, offset, orderBy, hasuraFilters]);

  useEffect(() => {
    if (!isRefetching) {
      setLoading(false);
    }
  }, [isRefetching, syncs]);

  return (
    <>
      <Column sx={{ mb: 3, width: "100%" }}>
        <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 8 }}>
          <Row sx={{ alignItems: "center" }}>
            <Heading sx={{ mr: 2 }}>Syncs</Heading>
            <Views value={selectedView} views={views} onChange={selectView} onDelete={deleteView} />
            {viewNotSaved &&
              (selectedView === "Default view" ? (
                <Button
                  sx={{ ml: 2 }}
                  variant="purple"
                  onClick={() => {
                    setCreateViewModalOpen(true);
                  }}
                >
                  Save as
                </Button>
              ) : (
                <DropdownButton
                  loading={updatingView}
                  options={[
                    {
                      label: "Save as",
                      onClick: () => {
                        setCreateViewModalOpen(true);
                      },
                    },
                    {
                      label: "Reset changes",
                      onClick: () => {
                        resetViewFilters();
                      },
                    },
                  ]}
                  sx={{ ml: 2 }}
                  onClick={updateCurrentView}
                >
                  Save
                </DropdownButton>
              ))}
          </Row>
          <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
            <Button
              onClick={() => {
                analytics.track("Add Sync Clicked");
                navigate(`/syncs/new`);
              }}
            >
              Add sync
            </Button>
          </Permission>
        </Row>
        <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
            <SearchInput placeholder={`Search syncs by model or destination...`} value={search ?? ""} onChange={setSearch} />

            <Filters
              data={allSyncs?.syncs ?? []}
              filterDefinitions={syncFilterDefinitions}
              filters={filters}
              resourceType="sync"
              sx={{ ml: 2 }}
              onChange={updateFilters}
            />
          </Box>

          {selectedRows.length > 0 && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Text as="label" sx={{ display: "flex", alignItems: "center" }}>
                <Text as="span" sx={{ color: "base.5" }}>{`${pluralize("sync", selectedRows.length, true)} selected`}</Text>

                <Menu
                  options={[
                    { label: "Disable", onClick: () => bulkUpdateStatus(false) },
                    { label: "Enable", onClick: () => bulkUpdateStatus(true) },
                    {
                      label: "Delete",
                      disabled: !userCanWrite,
                      onClick: () => setConfirmingDelete(true),
                      sx: { color: "red", ":hover": { backgroundColor: "reds.0" } },
                    },
                  ]}
                >
                  <Button
                    propagate
                    iconAfter={<ChevronDownIcon size={16} />}
                    loading={processingAction}
                    sx={{ ml: 3 }}
                    variant="secondary"
                  >
                    Select action
                  </Button>
                </Menu>
              </Text>
            </Box>
          )}
        </Row>
      </Column>
      <Table
        columns={columns}
        data={syncs}
        error={Boolean(syncsError) || Boolean(destinationsError)}
        loading={initialLoading || (loading && isRefetching)}
        placeholder={tablePlaceholder}
        selectedRows={selectedRows}
        onRowClick={onRowClick}
        onSelect={onRowSelect}
      />
      <Pagination count={syncsCount} label="syncs" page={page} rowsPerPage={limit} setPage={setPage} />

      <Modal
        bodySx={{ borderRadius: 2, pb: 5 }}
        footer={
          <>
            <Button variant="secondary" onClick={() => setConfirmingDelete(false)}>
              Cancel
            </Button>
            <Button loading={loadingBulkDelete} variant="red" onClick={bulkDelete}>
              Delete
            </Button>
          </>
        }
        header={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <InfoIcon sx={{ color: "red", mr: 3 }} />
            <Heading>
              Delete {pluralize("this", selectedRows.length, false)} {pluralize("Sync", selectedRows.length, false)}?
            </Heading>
          </Box>
        }
        isOpen={confirmingDelete}
        sx={{ borderRadius: 0, width: "600px" }}
        onClose={() => setConfirmingDelete(false)}
      >
        You will lose your sync {pluralize("configuration", selectedRows.length, false)}.
      </Modal>

      <CreateViewModal
        isOpen={createViewModalOpen}
        loading={creatingView}
        onClose={() => setCreateViewModalOpen(false)}
        onSave={createView}
      />
    </>
  );
};

export const Syncs: FC = () => (
  <Page crumbs={[{ label: "Syncs", link: "/syncs" }]} size="full">
    <PermissionProvider permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
      <SyncsContent />
    </PermissionProvider>
  </Page>
);

const tablePlaceholder = {
  title: "No syncs",
  body: "Add a sync to get started",
  error: "Syncs failed to load, please try again.",
};
