import { VFC } from "react";

import { Text, Image } from "theme-ui";

import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { IconProps } from "src/ui/icons/icon";
import { Link } from "src/ui/link";
import { Tooltip } from "src/ui/tooltip";

interface Props {
  title: string;
  subtitle: string;
  description: string;
  image: string;
  icon: VFC<IconProps>;
  integrations?: { icon: VFC<IconProps>; name: string }[];
}

export const Overview: VFC<Readonly<Props>> = ({ title, subtitle, description, image, icon: Icon, integrations }) => {
  return (
    <>
      <Row sx={{ justifyContent: "space-between" }}>
        <Column sx={{ flex: 1, mr: 10, maxWidth: "664px", alignItems: "flex-start" }}>
          <Icon size={32} sx={{ mb: 5 }} />
          <Heading sx={{ mb: 5, fontSize: 7 }}>{title}</Heading>
          <Text sx={{ mb: 5, color: "base.7", fontSize: 2 }}>{subtitle}</Text>
          <Text sx={{ mb: 5, color: "base.5" }}>{description}</Text>
          <Link to="configuration">
            <Button propagate>{`Configure extension ->`}</Button>
          </Link>
          {integrations && integrations?.length > 0 && (
            <Column sx={{ mt: 10 }}>
              <Text sx={{ textTransform: "uppercase", color: "base.5", mb: 4, fontSize: 0, fontWeight: "bold" }}>
                Integrations
              </Text>
              <Row>
                {integrations.map(({ icon: Icon, name }) => (
                  <Row key={name} sx={{ p: 3, border: "small", borderRadius: 2, mr: 2 }}>
                    <Tooltip hasCaret text={name}>
                      <Icon size={24} />
                    </Tooltip>
                  </Row>
                ))}
              </Row>
            </Column>
          )}
        </Column>
        <Column sx={{ flex: 1 }}>
          <Image src={image} />
        </Column>
      </Row>
    </>
  );
};
