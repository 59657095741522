import { Field, FieldError } from "src/ui/field";
import { Select } from "src/ui/select";
import { SensitiveField } from "src/ui/sensitive-field";

const regionOptions = [
  {
    name: "eu-central-1",
    description: "Frankfurt",
  },
  {
    name: "us-east-1",
    description: "N. Virginia",
  },
  {
    name: "us-west-2",
    description: "Oregon",
  },
];

const formattedRegionOptions = regionOptions.map((a) => ({ label: `${a.description} (${a.name})`, value: a.name }));

export const RocksetForm = ({ config, setConfig, error, hideSecret = false }) => {
  return (
    <>
      <Field label="Region">
        <Select
          options={formattedRegionOptions}
          placeholder="us-east-1"
          value={formattedRegionOptions?.find((o) => o.value === config?.region?.toLowerCase()) || null}
          onChange={(selected) => {
            const val = selected?.value;
            setConfig({ ...config, region: val });
          }}
        />
      </Field>

      <SensitiveField
        hideSecret={hideSecret}
        label="API Key"
        value={config?.apiKey || ""}
        onChange={(apiKey) => setConfig({ ...config, apiKey })}
      />

      <FieldError error={error} />
    </>
  );
};
