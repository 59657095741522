import { VFC, useState, useMemo, useEffect, useCallback } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import pluralize from "pluralize";
import { useToasts } from "react-toast-notifications";
import { Text } from "theme-ui";

import { AudiencesDemo } from "src/components/audiences/audiences-demo";
import { Filters, getHasuaExpFromFilters } from "src/components/filter";
import { audienceFilterDefinitions } from "src/components/filter/config";
import { CreateViewModal } from "src/components/filter/create-view";
import { Views } from "src/components/filter/views";
import { EditLabels } from "src/components/labels/edit-labels";
import { LabelsCell } from "src/components/labels/labels-cell";
import { Page } from "src/components/layout";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { Permission } from "src/components/permission";
import { SyncsCell } from "src/components/syncs/syncs-cell";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  ResourcePermissionGrant,
  useAddLabelsToAudiencesMutation,
  useAudiencesQuery,
  useDeleteModelsMutation,
  SegmentsBoolExp,
  SegmentsOrderBy,
  useResourceLabelsQuery,
} from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { Fade } from "src/ui/animations/fade";
import { Row, Column } from "src/ui/box";
import { Button, DropdownButton } from "src/ui/button";
import { LabelButton } from "src/ui/button/label-button";
import { Heading } from "src/ui/heading";
import { CogIcon } from "src/ui/icons";
import { SearchInput } from "src/ui/input";
import { Table, Pagination, useTableConfig, TableColumn } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useFiltering } from "src/ui/table/use-filtering";
import { useRowSelect } from "src/ui/table/use-row-select";
import { useDestinations } from "src/utils/destinations";
import { useNavigate } from "src/utils/navigate";
import { abbreviateNumber } from "src/utils/numbers";
import { openUrl } from "src/utils/urls";

enum SortKeys {
  Name = "name",
  NumSyncs = "syncs_aggregate.count",
  UpdatedAt = "updated_at",
}

export const Audiences: VFC = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [search, setSearch] = useQueryState("search");
  const { audiencesEnabled } = useFlags();

  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const [addingLabels, setAddingLabels] = useState<boolean>(false);
  const [createViewModalOpen, setCreateViewModalOpen] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [loading, setLoading] = useState<boolean>(true);

  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<SegmentsOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const {
    state: { creatingView, filters, selectedView, viewNotSaved, views, updatingView },
    actions: { createView, deleteView, resetViewFilters, selectView, updateCurrentView, updateFilters },
  } = useFiltering({ viewKey: "audience" });

  const { mutateAsync: addLabels, isLoading: loadingAddLabels } = useAddLabelsToAudiencesMutation();
  const { mutateAsync: bulkDelete, isLoading: loadingBulkDelete } = useDeleteModelsMutation();

  const { data: allAudiencesData } = useAudiencesQuery({ offset: 0, limit: 1000, orderBy });

  const hasuraFilters = useMemo(() => {
    const hasuraFilters: SegmentsBoolExp = {
      ...getHasuaExpFromFilters(audienceFilterDefinitions, filters),
      query_type: { _eq: "visual" },
    };

    if (search) {
      hasuraFilters.name = { _ilike: `%${search}%` };
    }

    return hasuraFilters;
  }, [filters, search]);

  const {
    data,
    error,
    isLoading: initialLoading,
    isRefetching,
    refetch,
    status,
  } = useAudiencesQuery(
    {
      filters: hasuraFilters,
      offset,
      limit,
      orderBy,
    },
    {
      enabled: Boolean(filters),
      refetchInterval: 3000,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );
  const { data: audienceLabels } = useResourceLabelsQuery({ resource: "segment" });

  const audiencesLoading = initialLoading || status === "idle" || loading;

  const {
    data: { definitions: destinationDefinitions },
  } = useDestinations();

  const allAudiences = useMemo(
    () =>
      allAudiencesData?.segments?.map((audience) => ({
        ...audience,
        syncs: audience.syncs.map((sync) => ({
          ...sync,
          destination: {
            ...sync.destination,
            definition: sync.destination ? destinationDefinitions?.[sync.destination.type] : undefined,
          },
        })),
      })),
    [destinationDefinitions, allAudiencesData],
  );

  const audiences = data?.segments ?? [];
  const audiencesCount = data?.segments_aggregate?.aggregate?.count ?? 0;

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        sortDirection: orderBy?.name,
        onClick: () => onSort(SortKeys.Name),
        cell: ({ name, labels }) => {
          return (
            <Row sx={{ alignItems: "center" }}>
              <Text>{name}</Text>
              <LabelsCell labels={labels} />
            </Row>
          );
        },
      },
      {
        name: "Size",
        key: "query_runs.[0].size",
        max: "max-content",
        cell: (size) => (size ? <Text>{abbreviateNumber(size)}</Text> : <Text sx={{ color: "base.4" }}>--</Text>),
      },
      {
        name: "Syncs",
        sortDirection: orderBy?.syncs_aggregate?.count,
        onClick: () => onSort(SortKeys.NumSyncs),
        max: "max-content",
        disabled: ({ syncs }) => Boolean(syncs?.length),
        cell: ({ syncs }) => {
          return <SyncsCell definitions={destinationDefinitions ?? []} syncs={syncs} />;
        },
      },
      {
        ...LastUpdatedColumn,
        sortDirection: orderBy?.updated_at,
        onClick: () => onSort(SortKeys.UpdatedAt),
      },
    ],
    [destinationDefinitions, orderBy],
  );

  const placeholder = useMemo(
    () => ({
      title: "No audiences",
      body: search
        ? "No audiences match your search"
        : filters?.length
        ? "No audiences match your filters"
        : "Add an audience to get started",
      error: "Audiences failed to load, please try again.",
    }),
    [hasuraFilters],
  );

  const onRowClick = useCallback(({ id }, event) => openUrl(`/audiences/${id}`, navigate, event), [navigate]);

  useEffect(() => {
    setPage(0);
  }, [hasuraFilters]);

  useEffect(() => {
    onRowSelect([]);
  }, [page]);

  useEffect(() => {
    setLoading(true);
  }, [limit, offset, orderBy, hasuraFilters]);

  useEffect(() => {
    if (!isRefetching) {
      setLoading(false);
    }
  }, [audiences, isRefetching]);

  if (!audiencesEnabled) {
    return <AudiencesDemo />;
  }

  return (
    <>
      <PermissionProvider permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Update] }]}>
        <Page crumbs={[{ label: "Audiences" }]} size="full">
          <Column sx={{ mb: 3, width: "100%" }}>
            <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 8 }}>
              <Row sx={{ alignItems: "center" }}>
                <Heading sx={{ mr: 2 }}>Audiences</Heading>
                <Views value={selectedView} views={views} onChange={selectView} onDelete={deleteView} />
                {viewNotSaved &&
                  (selectedView === "Default view" ? (
                    <Button
                      sx={{ ml: 2 }}
                      variant="purple"
                      onClick={() => {
                        setCreateViewModalOpen(true);
                      }}
                    >
                      Save as
                    </Button>
                  ) : (
                    <DropdownButton
                      loading={updatingView}
                      options={[
                        {
                          label: "Save as",
                          onClick: () => {
                            setCreateViewModalOpen(true);
                          },
                        },
                        {
                          label: "Reset changes",
                          onClick: () => {
                            resetViewFilters();
                          },
                        },
                      ]}
                      sx={{ ml: 2 }}
                      onClick={updateCurrentView}
                    >
                      Save
                    </DropdownButton>
                  ))}
              </Row>
              <Row sx={{ alignItems: "center" }}>
                <Permission permissions={[{ resource: "audience_schema", grants: [ResourcePermissionGrant.Read] }]}>
                  <Button
                    iconAfter={<CogIcon color="base.5" size={14} />}
                    sx={{ mr: 2 }}
                    variant="secondary"
                    onClick={() => navigate(`/audiences/setup/parent-models`)}
                  >
                    Setup
                  </Button>
                </Permission>
                <Permission permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Create] }]}>
                  <Button
                    onClick={() => {
                      analytics.track("Add Audience Clicked");
                      navigate("/audiences/new");
                    }}
                  >
                    Add audience
                  </Button>
                </Permission>
              </Row>
            </Row>
            <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
              <Row sx={{ alignItems: "center" }}>
                <SearchInput placeholder={`Search audiences by name...`} value={search ?? ""} onChange={setSearch} />

                <Filters
                  data={allAudiences}
                  filterDefinitions={audienceFilterDefinitions}
                  filters={filters}
                  resourceType="segment"
                  sx={{ ml: 2 }}
                  onChange={(filters) => {
                    updateFilters(filters);
                    refetch();
                  }}
                />
              </Row>

              <Permission permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Delete] }]}>
                <Fade hidden={!selectedRows.length} sx={{ display: "flex", alignItems: "center" }}>
                  <Button sx={{ mr: 4 }} variant="secondary" onClick={() => onRowSelect([])}>
                    Cancel
                  </Button>
                  <Button sx={{ mr: 2 }} variant="soft" onClick={() => setConfirmingDelete(true)}>
                    Delete
                  </Button>

                  <LabelButton onClick={() => setAddingLabels(true)} />
                </Fade>
              </Permission>
            </Row>
          </Column>

          <Table
            columns={columns}
            data={audiences}
            error={Boolean(error)}
            loading={audiencesLoading}
            placeholder={placeholder}
            selectedRows={selectedRows}
            onRowClick={onRowClick}
            onSelect={onRowSelect}
          />

          <Pagination count={audiencesCount} label="audiences" page={page} rowsPerPage={limit} setPage={setPage} />
        </Page>
      </PermissionProvider>

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="audience"
        loading={loadingBulkDelete}
        onClose={() => setConfirmingDelete(false)}
        onDelete={async () => {
          await bulkDelete({ ids: selectedRows.map(String) });
          onRowSelect([]);
        }}
      />
      <EditLabels
        description="You can label audiences that have similar properties"
        existingLabelOptions={audienceLabels?.resource_tag_values}
        hint="Example keys: team, project, region, env."
        isOpen={addingLabels}
        loading={loadingAddLabels}
        saveLabel={`Apply to ${selectedRows.length} ${pluralize("audience", selectedRows.length)}`}
        title="Add labels"
        onClose={() => setAddingLabels(false)}
        onSave={async (labels) => {
          const labelCount = Object.keys(labels).length;
          await addLabels({ ids: selectedRows.map(String), labels });
          setAddingLabels(false);
          addToast(
            `Added ${labelCount} ${pluralize("label", labelCount)} to ${selectedRows.length} ${pluralize(
              "audience",
              selectedRows.length,
            )}`,
            {
              appearance: "success",
            },
          );
          onRowSelect([]);
        }}
      />

      <CreateViewModal
        isOpen={createViewModalOpen}
        loading={creatingView}
        onClose={() => setCreateViewModalOpen(false)}
        onSave={createView}
      />
    </>
  );
};
