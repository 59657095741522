import { FC, useEffect, useMemo, useState, VFC } from "react";

import { isEqual, omitBy } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";
import { Grid, Text } from "theme-ui";

import { JsonEditor } from "src/components/destinations/json-editor";
import { TestSync } from "src/components/destinations/test-sync";
import { SidebarForm } from "src/components/page";
import { DestinationFormProvider } from "src/contexts/destination-form-context";
import { Form } from "src/formkit/components/form";
import {
  ExternalSegment,
  FormkitDestination,
  FormkitModel,
  FormkitProvider,
  FormkitSync,
} from "src/formkit/components/formkit-context";
import { processFormNode } from "src/formkit/formkit";
import {
  DestinationDefinitionFragment as DestinationDefinition,
  FormkitSyncDefinitionQuery,
  SourceDefinitionFragment as SourceDefinition,
  useFormkitSyncDefinitionQuery,
  useFormkitSyncValidationQuery,
  ResourcePermissionGrant,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { validate as oldValidate } from "src/utils/destinations";

import { Permission } from "../permission";
import ActiveCampaignDestination from "./forms/active-campaign";
import AmplitudeDestination from "./forms/amplitude";
import AsanaDestination from "./forms/asana";
import ChurnzeroDestination from "./forms/churnzero";
import ClientSuccessForm from "./forms/client-success";
import CloseIODestination from "./forms/closeio";
import CustomDestination from "./forms/custom";
import CustomerIODestination from "./forms/customerio";
import FacebookDestination from "./forms/facebook";
import FacebookProductCatalog from "./forms/facebook-ad-catalog";
import FacebookConversions from "./forms/facebook-conversions";
import FacebookOffline from "./forms/facebook-offline";
import GainsightpxDestination from "./forms/gainsightpx";
import GoogleAdsDestination from "./forms/google-ads";
import GoogleCampaignManagerDestination from "./forms/google-campaign-manager";
import HeapDestination from "./forms/heap";
import HubspotLegacy from "./forms/hubspot-legacy";
import JiraDestination from "./forms/jira";
import MarketoDestination from "./forms/marketo";
import MixpanelDestination from "./forms/mixpanel";
import MParticleDestination from "./forms/mparticle";
import OneSignalDestination from "./forms/onesignal";
import OrbitForm from "./forms/orbit";
import PartnerstackDestination from "./forms/partnerstack";
import PostgresDestination from "./forms/postgres";
import QualtricsDestination from "./forms/qualtrics";
import ReplyioDestination from "./forms/replyio";
import RudderStackDestination from "./forms/rudderstack";
import SalesloftDestination from "./forms/salesloft";
import SegmentDestination from "./forms/segment";
import SendgridDestination from "./forms/sendgrid";
import SfmcDestination from "./forms/sfmc";
import SfmcFileDropDestination from "./forms/sfmc-file-drop";
import SlackDestination from "./forms/slack";
import TiktokAdsDestination from "./forms/tiktok";
import TotangoDestination from "./forms/totango";
import TradedeskPostbackForm from "./forms/tradedesk-postback";
import UserflowDestination from "./forms/userflow";
import VeroDestination from "./forms/vero";

type FormkitDefinition = FormkitSyncDefinitionQuery["formkitSyncDefinition"];

interface DestinationFormContext {
  sync: FormkitSync | undefined;
  slug: string | undefined | null;
  model: FormkitModel | undefined;
  destination: FormkitDestination | undefined;
  destinationDefinition: DestinationDefinition;
  sourceDefinition: SourceDefinition | undefined;
  externalSegment: ExternalSegment | undefined;
}

export const DESTINATION_FORMS = {
  asana: AsanaDestination,
  activeCampaign: ActiveCampaignDestination,
  amplitude: AmplitudeDestination,
  segment: SegmentDestination,
  rudderstack: RudderStackDestination,
  onesignal: OneSignalDestination,
  facebook: FacebookDestination,
  hubspotLegacy: HubspotLegacy,
  totango: TotangoDestination,
  mparticle: MParticleDestination,
  slack: SlackDestination,
  sfmc: SfmcDestination,
  sfmcFileDrop: SfmcFileDropDestination,
  customerio: CustomerIODestination,
  sendgrid: SendgridDestination,
  mixpanel: MixpanelDestination,
  google: GoogleAdsDestination,
  googleCampaignManager: GoogleCampaignManagerDestination,
  postgres: PostgresDestination,
  marketo: MarketoDestination,
  churnzero: ChurnzeroDestination,
  closeio: CloseIODestination,
  facebookConversions: FacebookConversions,
  facebookOffline: FacebookOffline,
  facebookAdCatalog: FacebookProductCatalog,
  partnerstack: PartnerstackDestination,
  vero: VeroDestination,
  gainsightpx: GainsightpxDestination,
  salesloft: SalesloftDestination,
  tiktok: TiktokAdsDestination,
  orbit: OrbitForm,
  clientSuccess: ClientSuccessForm,
  tradedeskPostback: TradedeskPostbackForm,
  custom: CustomDestination,
  qualtrics: QualtricsDestination,
  replyio: ReplyioDestination,
  jira: JiraDestination,
  userflow: UserflowDestination,
  heap: HeapDestination,
};

type Props = {
  model?: FormkitModel;
  destination?: FormkitDestination;
  sync?: FormkitSync;
  syncConfig?: any;
  destinationDefinition: DestinationDefinition;
  sourceDefinition: SourceDefinition | undefined;
  externalSegment?: ExternalSegment;
  slug: string | undefined;
  onSubmit: (config: any) => Promise<void>;
  hideSave?: boolean;
};

export const DestinationForm: FC<Readonly<Props>> = ({
  sync,
  syncConfig,
  model,
  destination,
  destinationDefinition,
  sourceDefinition,
  externalSegment,
  slug,
  onSubmit,
  hideSave,
}) => {
  const [enabled, setEnabled] = useState<boolean>(Boolean(destination));
  const {
    error: formkitDefinitionError,
    data,
    isLoading: formkitDefinitionLoading,
  } = useFormkitSyncDefinitionQuery(
    { type: destination?.type ?? "" },
    {
      enabled,
      onSettled: () => {
        setEnabled(false);
      },
    },
  );

  const formkitDefinition = data?.formkitSyncDefinition;

  const context = {
    sync,
    slug,
    model,
    destination,
    destinationDefinition,
    sourceDefinition,
    externalSegment,
  };

  const deprecatedForm = slug ? DESTINATION_FORMS[slug] : undefined;

  if (!destination || !model || !sourceDefinition) {
    return (
      <Text>You do not have access to the underlying model, source or destination needed to edit this configuration.</Text>
    );
  }

  if (!formkitDefinitionError && formkitDefinitionLoading) {
    return null;
  }

  return (
    <DestForm
      context={context}
      deprecatedForm={deprecatedForm}
      formkitDefinition={formkitDefinition}
      hideSave={hideSave}
      syncConfig={syncConfig || sync?.config}
      onSubmit={onSubmit}
    />
  );
};

interface DestFormProps {
  syncConfig: unknown;
  context: DestinationFormContext;
  formkitDefinition: FormkitDefinition;
  deprecatedForm: typeof DESTINATION_FORMS[keyof typeof DESTINATION_FORMS];
  onSubmit: (config: any) => void;
  hideSave: boolean | undefined;
}

const DestForm: VFC<DestFormProps> = ({ syncConfig, context, formkitDefinition, deprecatedForm, onSubmit, hideSave }) => {
  const client = useQueryClient();
  const { addToast } = useToasts();

  const [customValidation, setCustomValidation] = useState<{
    validate: (config: any) => Promise<{ yupError?: any; otherError?: any }>;
  }>();
  const [editingJson, setEditingJson] = useState(false);
  const [initialConfig, setConfig] = useState<any>(syncConfig || {});
  const [saving, setSaving] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>();

  const DeprecatedForm = deprecatedForm?.form;

  const formkitValidate = async (config) => {
    const response = await client.fetchQuery({
      queryFn: useFormkitSyncValidationQuery.fetcher({ type: context.destination?.type ?? "", config }),
      queryKey: useFormkitSyncValidationQuery.getKey(config),
    });

    return response.formkitSyncValidation;
  };

  const formkit = useMemo(() => {
    if (!formkitDefinition) {
      return null;
    }
    return processFormNode(formkitDefinition);
  }, [formkitDefinition]);

  const methods = useForm({ defaultValues: initialConfig });

  const config = formkitDefinition ? methods.getValues() : deprecatedForm?.validation?.cast(initialConfig);

  const isConfigChanged = !isEqual(
    syncConfig,
    omitBy(config, (v) => v === undefined),
  );

  const validate = async (config) => {
    if (formkitDefinition) {
      const errors = await formkitValidate(config);
      if (typeof errors === "object" && Object.keys(errors).length) {
        return errors;
      }
    } else {
      return oldValidate(config, deprecatedForm.validation, customValidation);
    }
  };

  const handleSubmit = async () => {
    setSaving(true);
    if (formkitDefinition) {
      await methods.handleSubmit(async (data) => {
        const errors = await formkitValidate(data);
        if (typeof errors === "object" && Object.keys(errors).length) {
          Object.entries(errors).forEach(([key, message]) => {
            methods.setError(key, { message: String(message) });
          });
          analytics.track("Destination Config Validation Error");
          addToast("There was an error saving the sync configuration.", {
            appearance: "error",
          });
          setErrors(errors);
        } else {
          await onSubmit(data);
          setErrors(null);
        }
      })();
    } else {
      const errors = await oldValidate(config, deprecatedForm.validation, customValidation);
      if (errors) {
        analytics.track("Destination Config Validation Error");
        addToast("There was an error saving the sync configuration.", {
          appearance: "error",
        });
        setErrors(errors);
      } else {
        await onSubmit(config);
        setErrors(null);
      }
    }
    setSaving(false);
  };

  useEffect(() => {
    if (editingJson) {
      analytics.track("Sync JSON Editor Opened", {
        sync_id: context.sync?.id,
        sync_slug: context.slug,
        model_name: context.model?.name,
        query_type: context.model?.query_type,
        destination_type: context.destination?.type,
        source_type: context.sourceDefinition?.type,
      });
    }
  }, [editingJson]);

  useEffect(() => {
    if (errors) {
      analytics.track("Sync Destination Configuration Error", {
        sync_id: context.sync?.id,
        sync_slug: context.slug,
        model_name: context.model?.name,
        query_type: context.model?.query_type,
        destination_type: context.destination?.type,
        source_type: context.sourceDefinition?.type,
        errors,
      });
    }
  }, [errors]);

  return (
    <FormkitProvider {...context} validate={validate}>
      <DestinationFormProvider
        config={config}
        errors={errors}
        setConfig={setConfig}
        setCustomValidation={setCustomValidation}
        setErrors={setErrors}
      >
        <FormProvider {...methods}>
          <Row sx={{ flexGrow: 1, alignItems: "flex-start" }}>
            <Row mr={8} sx={{ flexGrow: 1 }}>
              {formkitDefinition ? (
                <Form>{formkit}</Form>
              ) : (
                <Grid gap={12} sx={{ flex: 1, "& > div:not(:last-child)": { pb: 12, borderBottom: "small" } }}>
                  <DeprecatedForm />
                </Grid>
              )}
              <form
                hidden
                id="destination-form"
                onSubmit={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              ></form>
            </Row>
            <SidebarForm
              hideCompliance
              buttons={[
                <Permission
                  key={1}
                  permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Start], resource_id: context.sync?.id }]}
                >
                  {!hideSave && (
                    <Button
                      disabled={!isConfigChanged}
                      loading={saving}
                      sx={{ width: "100%" }}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Save
                    </Button>
                  )}
                  <TestSync formkit={formkit} />
                  <Button
                    label="Edit as JSON"
                    size="small"
                    sx={{ width: "100%" }}
                    variant="secondary"
                    onClick={() => {
                      setEditingJson(true);
                    }}
                  />
                </Permission>,
              ]}
              docsUrl={`${import.meta.env.VITE_DOCS_URL}/${context.destinationDefinition.docs}`}
              invite="If you need help setting up this sync"
              name={context.destinationDefinition.name}
            />
          </Row>
          {editingJson && (
            <JsonEditor
              formkit={Boolean(formkitDefinition)}
              onClose={() => {
                setEditingJson(false);
              }}
            />
          )}
        </FormProvider>
      </DestinationFormProvider>
    </FormkitProvider>
  );
};
